<template>
  <div class="page-wrapper">
    <UserTable
      :rows="userArray"
      :pagination="userPagination"
      :loading="isDataLoading"
      @change-page="getPaginatedUsers"
      @edit="crudMixin_openForm('user', $event)"
      @delete="deleteUser"
      @impersonate="impersonateUser"
      @new-item="crudMixin_openForm('user', newUserTemplate)"
      @reset-password="resetUserPassword"
      @toggle-status="toggleUserStatus"
      @update:search="getPaginatedUsers(userPagination.page, $event)"
    />

    <v-dialog
      v-model="isUserFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      scrollable
      persistent
    >
      <UserForm
        :dialog="isUserFormOpen"
        :form-item="userFormItem"
        @cancel="isUserFormOpen = false"
        @create="crudMixin_created('user', $event)"
        @update="crudMixin_updated('user', $event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import UserForm from '../components/forms/UserForm.vue';
import UserTable from '../components/tables/UserTable.vue';
import userService from '../api/user-service';
import crudMixin from '../mixins/crud-mixin';
import eventBus, {
  CONFIRM_DIALOG_CLOSED,
  OPEN_CONFIRM_DIALOG,
  OPEN_SNACKBAR,
} from '../util/event-bus';
import { IMPERSONATE_USER } from '../store/action-types';

export default {
  name: 'Users',

  components: {
    UserForm,
    UserTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      userArray: [],
      userPagination: {
        page: 1,
      },
      userFilterParams: '',
      userFormItem: {},
      isUserFormOpen: false,
      isDataLoading: true,
      newUserTemplate: {
        person: {},
        status: 'active',
        role: 'user',
        permissions: [],
      },
    };
  },

  created() {
    this.getPaginatedUsers(1);
  },

  methods: {
    getPaginatedUsers(pageNo, params) {
      if (params) {
        this.userFilterParams = params;
      }
      this.crudMixin_getPage(userService.getPage, userService.model, pageNo, this.userFilterParams);
    },

    deleteUser(user) {
      this.crudMixin_delete(userService.delete, userService.model, user);
    },

    async toggleUserStatus(user) {
      await this.crudMixin_update(userService.toggleStatus, userService.model, user);
      const newStatus = user.status === 'active' ? 'suspended' : 'active';
      this.crudMixin_updated('user', { ...user, status: newStatus });
    },

    resetUserPassword(user) {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, {
        title: this.$t('confirm_password_reset'),
      });
      eventBus.$on(CONFIRM_DIALOG_CLOSED, async (confirmed) => {
        if (confirmed) {
          await userService.resetPassword(user);
          eventBus.$emit(OPEN_SNACKBAR, this.$t('password_was_reset'));
        }
        eventBus.$off(CONFIRM_DIALOG_CLOSED);
      });
    },

    impersonateUser(user) {
      this.$store.dispatch(IMPERSONATE_USER, user.id);
    },
  },
};
</script>
