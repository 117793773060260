var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableLoader',{attrs:{"loading":_vm.loading}},[_c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
      'items-per-page-options': [_vm.pagination.rowsPerPage],
    },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.displayedItems,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"disable-sort":""},on:{"update:page":_vm.tableMixin_changePage,"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BaseTableHeader',{attrs:{"title":_vm.$t('users'),"create-button":_vm.$t('create_user'),"show-search":""},on:{"new-item":function($event){return _vm.$emit('new-item')},"update:search":function($event){return _vm.$emit('update:search', $event)}}})]},proxy:true},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.phone",fn:function(ref){
    var item = ref.item;
return [(item.phone)?_c('div',[_c('a',{attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(item.phone))])]):_vm._e(),(item.phone2)?_c('div',[_c('a',{attrs:{"href":("tel:" + (item.phone2))}},[_vm._v(_vm._s(item.phone2))])]):_vm._e()]}},{key:"item.address",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":("https://maps.google.com/?q=" + (item.address)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.address)+" ")])]}},{key:"item.translatedStatus",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"text-caption persist-opacity",attrs:{"color":item.status === 'suspended' ? 'grey lighten-4' : 'green accent-2',"label":"","small":""}},[_vm._v(" "+_vm._s(item.translatedStatus)+" ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item}})]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.email",fn:function(){return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]},proxy:true},{key:"item.phone",fn:function(){return [(item.phone)?_c('a',{staticClass:"mr-3",attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(item.phone))]):_vm._e(),(item.phone2)?_c('a',{attrs:{"href":("tel:" + (item.phone2))}},[_vm._v(_vm._s(item.phone2))]):_vm._e()]},proxy:true},{key:"item.address",fn:function(){return [_c('a',{attrs:{"href":("https://maps.google.com/?q=" + (item.address)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.address)+" ")])]},proxy:true},{key:"item.translatedStatus",fn:function(){return [_c('v-chip',{staticClass:"text-caption persist-opacity",attrs:{"color":item.status === 'suspended' ? 'grey lighten-4' : 'green accent-2',"label":"","small":""}},[_vm._v(" "+_vm._s(item.translatedStatus)+" ")])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }