<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="displayedItems"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      disable-sort
      @update:page="tableMixin_changePage"
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:top>
        <BaseTableHeader
          :title="$t('users')"
          :create-button="$t('create_user')"
          show-search
          @new-item="$emit('new-item')"
          @update:search="$emit('update:search', $event)"
        />
      </template>
      <template v-slot:item.email="{ item }">
        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
      </template>
      <template v-slot:item.phone="{ item }">
        <div v-if="item.phone">
          <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
        </div>
        <div v-if="item.phone2">
          <a :href="`tel:${item.phone2}`">{{ item.phone2 }}</a>
        </div>
      </template>
      <template v-slot:item.address="{ item }">
        <a :href="`https://maps.google.com/?q=${item.address}`" target="_blank">
          {{ item.address }}
        </a>
      </template>
      <template v-slot:item.translatedStatus="{ item }">
        <v-chip
          :color="item.status === 'suspended' ? 'grey lighten-4' : 'green accent-2'"
          class="text-caption persist-opacity"
          label
          small
        >
          {{ item.translatedStatus }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          :actions="getRowActions(item)"
          :item="item"
        />
      </template>
      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.email>
            <a :href="`mailto:${item.email}`">{{ item.email }}</a>
          </template>
          <template v-slot:item.phone>
            <a v-if="item.phone" :href="`tel:${item.phone}`" class="mr-3">{{ item.phone }}</a>
            <a v-if="item.phone2" :href="`tel:${item.phone2}`">{{ item.phone2 }}</a>
          </template>
          <template v-slot:item.address>
            <a :href="`https://maps.google.com/?q=${item.address}`" target="_blank">
              {{ item.address }}
            </a>
          </template>
          <template v-slot:item.translatedStatus>
            <v-chip
              :color="item.status === 'suspended' ? 'grey lighten-4' : 'green accent-2'"
              class="text-caption persist-opacity"
              label
              small
            >
              {{ item.translatedStatus }}
            </v-chip>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import tableMixin from '../../mixins/table-mixin';
import BaseActionMenu from '../base/BaseActionMenu';
import BaseTableHeader from '../base/BaseTableHeader';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';

export default {
  name: 'UserTable',

  components: {
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseTableHeader,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('full_name'),
          value: 'full_name',
        },
        {
          text: this.$t('phone'),
          value: 'phone',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('email'),
          value: 'email',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('nin'),
          value: 'nin',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('address'),
          value: 'address',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('role'),
          value: 'translatedRole',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('status'),
          value: 'translatedStatus',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ],
    };
  },

  computed: {
    displayedItems() {
      return this.rows.map(r => ({
        ...r,
        ...r.person,
        translatedRole: this.$t(`roles.${r.role}`),
        translatedStatus: this.$t(`statuses.${r.status}`),
      }));
    },
  },

  methods: {
    getRowActions(user) {
      const actions = [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        // {
        //   callback: p => this.$emit('delete', p),
        //   label: this.$t('delete'),
        //   icon: 'mdi-delete',
        // },
        {
          callback: p => this.$emit('toggle-status', p),
          label: this.$t(user.status === 'active' ? 'suspend' : 'activate'),
          icon: user.status === 'active' ? 'mdi-cancel' : 'mdi-shield-check',
        },
        {
          callback: p => this.$emit('impersonate', p),
          label: this.$t('impersonate'),
          icon: 'mdi-account',
        },
        {
          callback: p => this.$emit('reset-password', p),
          label: this.$t('reset_password'),
          icon: 'mdi-lock-reset',
        },
      ];

      return this.$isCurrentUser(user.id) ? actions.slice(0, 1) : actions;
    },
  },
};
</script>
