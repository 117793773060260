<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.first_name"
              :error-messages="errors['person.first_name']"
              :label="formMixin_getRequiredFieldLabel($t('first_name'))"
              autofocus
              @blur="formMixin_clearErrors('person.first_name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.last_name"
              :error-messages="errors['person.last_name']"
              :label="formMixin_getRequiredFieldLabel($t('last_name'))"
              @blur="formMixin_clearErrors('person.last_name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.email"
              :error-messages="errors['email']"
              :label="formMixin_getRequiredFieldLabel($t('email'))"
              @blur="formMixin_clearErrors('email')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.nin"
              :error-messages="errors['person.nin']"
              :label="$t('nin')"
              @blur="formMixin_clearErrors('person.nin')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.phone"
              :error-messages="errors['person.phone']"
              :label="$t('phone')"
              @blur="formMixin_clearErrors('phone')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.phone2"
              :error-messages="errors['person.phone2']"
              :label="$t('phone2')"
              @blur="formMixin_clearErrors('phone2')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="user.person.address"
              :error-messages="errors['person.address']"
              :label="$t('address')"
              @blur="formMixin_clearErrors('person.address')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="user.role"
              :items="roles"
              :disabled="$isCurrentUser(user.id)"
              :error-messages="errors['role']"
              :label="$t('role')"
              @blur="formMixin_clearErrors('role')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="user.status"
              :items="statuses"
              :disabled="$isCurrentUser(user.id)"
              :error-messages="errors['status']"
              :label="$t('status')"
              @blur="formMixin_clearErrors('status')"
            />
          </v-col>

          <v-col cols="12">
            <h3 class="text-h6 mt-3">
              {{ $t('permissions.label') }}:
            </h3>
          </v-col>

          <v-col cols="12" v-if="user.role !== 'admin'">
            <v-treeview
              v-model="selectedPermissions"
              :items="permissionTree"
              expand-icon="mdi-chevron-down"
              selection-type="independent"
              item-key="permission"
              dense
              open-all
              open-on-click
              return-object
              selectable
            >
              <template v-slot:label="{ item }">
                {{ $t(`permissions['${item.permission}']`) }}
              </template>
            </v-treeview>
          </v-col>

          <v-col v-else>
            {{ $t('admin_has_all_permissions') }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!user.id" class="text-subtitle-2 ml-3">
          * {{ $t('must_be_filled') }}
        </span>

        <v-spacer/>

        <v-btn
          text
          color="primary"
          @click="$emit('cancel')"
        >
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          color="primary"
          text
          type="submit"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '../../mixins/form-mixin';
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import userService from '../../api/user-service';
import { availablePermissionsTree } from '../../store/modules/auth';

export default {
  name: 'UserForm',

  props: {
    formItem: Object,
  },

  mixins: [formMixin, crudMixin, dialogMixin],

  data() {
    return {
      errors: {},
      isRequestPending: false,
      roles: [
        { value: 'admin', text: this.$t('roles.admin') },
        { value: 'user', text: this.$t('roles.user') },
      ],
      statuses: [
        { value: 'active', text: this.$t('statuses.active') },
        { value: 'suspended', text: this.$t('statuses.suspended') },
      ],
      user: {},
      permissionTree: [...availablePermissionsTree],
      selectedPermissions: [],
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.user.id ? 'edit_user' : 'new_user');
    },
  },

  methods: {
    async onDialogOpen() {
      this.user = JSON.parse(JSON.stringify(this.formItem));
      await this.$nextTick();
      this.selectedPermissions = this.user.id
        ? [...this.user.permissions]
        : [];
      this.errors = {};
    },

    onSubmit() {
      this.user.permissions = [...this.selectedPermissions];
      this.crudMixin_createOrUpdate(userService, this.user);
    },
  },
};
</script>
